





















import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      loaded: false,
      items: [],
      search: "",
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant`, {
          params: {
            state: "participant",
            search: state.search || undefined,
          },
        })
        .then(({ data: { participants } }) => {
          state.items = participants.map((el: any) => ({
            id: el.id,
            firstName: el.firstName,
            lastName: el.lastName,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    watch(() => state.search, fetchData);

    return { state };
  },
});
